<template>
  <div class="edit-board-style">
    <Loading v-if="is_loading" />
    <content-not-view v-else-if="!subPermission.update" />
    <b-card v-else :title="$t('g.boardStyles/edit_board_style')">
      <b-row>
        <!-- form -->
        <b-col cols="9">
          <validation-observer ref="editBoardStyleForm">
            <b-form>
              <b-row>
                <!-- Name -->
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.boardStyles/board_style_name')"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('g.boardStyles/board_style_name')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form_data.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('g.placeholder.enterTheNameHere')"
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Name -->

                <!-- Language -->
                <b-col md="6">
                  <b-form-group :label="$t('g.language')" label-for="Language">
                    <validation-provider
                      #default="{ errors }"
                      name="Language"
                      rules="required"
                    >
                      <select class="form-control" v-model="form_data.lang">
                        <option selected value>
                          {{ $t("g.Please_Select_Language") }}
                        </option>
                        <option
                          v-for="(lang, index) in lang_options"
                          :key="index"
                          :value="lang.value"
                        >
                          {{ lang.text }}
                        </option>
                      </select>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Language -->

                <!-- Media -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('g.boardStyles/media')"
                    label-for="media"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="media"
                      rules="required"
                      accept="image/*"
                    >
                      <b-form-file
                        id="media"
                        v-model="form_data.media"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('g.boardStyles/drop_file_here')"
                        :drop-placeholder="$t('g.boardStyles/drop_file_here')"
                      ></b-form-file>
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Media -->

                <!-- Primary Color -->
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.boardStyles/primary_color')"
                    label-for="primary_color"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="primary_color"
                      rules="required"
                    >
                      <b-form-input
                        id="primary_color"
                        type="color"
                        v-model="form_data.primary_color"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Primary Color -->

                <!-- Secondary Color -->
                <b-col md="6">
                  <b-form-group
                    :label="$t('g.boardStyles/secondary_color')"
                    label-for="secondary_color"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="secondary_color"
                      :rules="`required|notEqual:${form_data.primary_color}`"
                    >
                      <b-form-input
                        id="secondary_color"
                        type="color"
                        v-model="form_data.secondary_color"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Secondary Color -->

                <!-- is_available -->
                <b-col md="4">
                  <b-form-group :label="$t('g.status')">
                    <validation-provider
                      #default="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <b-form-checkbox
                        switch
                        v-model="form_data.is_available"
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ $t("g.boardStyles/is_available") }}
                      </b-form-checkbox>
                      <small class="text-danger">{{
                        errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- is_available -->

                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                    :disabled="is_submitted"
                  >
                    <div
                      v-if="is_submitted"
                      class="loading_"
                      data-loading="light"
                    />
                    <small v-else>{{ $t("g.submit") }}</small>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
        <!-- form -->

        <!-- image -->
        <b-col cols="3">
          <b-card
            no-body
            class="overflow-hidden shadow-md"
            style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important"
          >
            <b-card-img
              :src="
                form_data.media
                  ? form_data.media.path
                  : require('@/assets/images/not-found.jpg')
              "
              alt="Image"
              class="img-fluid rounded bg-cover"
            />
          </b-card>
        </b-col>
        <!-- image -->
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardImg,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@core/utils/validations/validations";
import Loading from "@/components/loading/loading.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
extend("notEqual", {
  params: ["other"],
  validate: (value, { other }) => {
    if (value != other) {
      return true;
    }
    return "colors_mustn't_be_sames";
  },
});
export default {
  name: "EditBoardStyle",
  components: {
    Loading,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BCardImg,
    contentNotView,
  },
  data() {
    return {
      is_loading: true,
      is_submitted: false,
      lang_options: [
        { value: "ar", text: this.$t("g.Arabic") },
        { value: "en", text: this.$t("g.English") },
      ],
      form_data: {
        name: null,
        lang: null,
        primary_color: null,
        secondary_color: null,
        is_available: true,
        media: null,
      },
    };
  },
  beforeMount() {
    this.getBoardStyle();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "board-styles");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    getBoardStyle() {
      this.$http
        .get(`/admin/board-styles/${this.$route.params.id}`)
        .then((response) => {
          this.form_data.name = response.data.data.name;
          this.form_data.lang = response.data.data.lang;
          this.form_data.primary_color = response.data.data.primary_color;
          this.form_data.secondary_color = response.data.data.secondary_color;
          this.form_data.is_available = response.data.data.is_available;
          this.form_data.media = response.data.data.background_image
            ? response.data.data.background_image[0]
            : null;
        })
        .catch((error) => {
          this.$helpers.makeToast(
            "danger",
            this.$t("g.boardStyles/not_found"),
            this.$t("g.boardStyles/board_style_not_found")
          );
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    validationForm() {
      this.$refs.editBoardStyleForm.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append("name", this.form_data.name);
          formData.append("lang", this.form_data.lang);
          formData.append("primary_color", this.form_data.primary_color);
          formData.append("secondary_color", this.form_data.secondary_color);
          formData.append("is_available", this.form_data.is_available ? 1 : 0);

          if (this.form_data.media && !this.form_data.media.path) {
            formData.append("image", this.form_data.media);
          }

          this.$http
            .post(
              `/admin/board-styles/${this.$route.params.id}?_method=put`,
              formData
            )
            .then((response) => {
              this.$router.push({ name: "boardStylesList" });
              this.$helpers.makeToast(
                "success",
                this.$t("g.boardStyles/edited_successfully"),
                this.$t("g.boardStyles/board_style_edited_successfully")
              );
            })
            .catch((error) => {
              this.$helpers.handleError(error);
            })
            .finally(() => {
              this.is_submitted = false;
            });
        }
      });
    },
  },
};
</script>
